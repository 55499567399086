import React from 'react'

import { msalConfig, loginRequest } from './../utility/auth/msalConfig';
import { MsalProvider } from '../utility/auth/msalContext';

import classes from '../styles/faq.module.sass'
import { renderAst } from '../markdown'

import Wrapper from '../components/wrapper'


export default ({data}) => 
{
    const faqHtml = renderAst(data.markdownRemark.htmlAst)
    return (
        <MsalProvider
            config={msalConfig}
            scopes={loginRequest}
            >
            <Wrapper>
                <div className={classes.container}>
                    <h1 className={classes.pagetitle}>FAQ</h1>
                    {faqHtml} 
                </div>
                <p className={classes.pagetitle}>Om du har frågor kan du kontakta oss genom att skicka mejl till adressen <a className={classes.link} href="mailto: ai-for-naturligt-sprak@ida.liu.se">ai-for-naturligt-sprak@ida.liu.se</a>.</p>   

                
            </Wrapper>
        </MsalProvider>
    )
}

export const pageQuery = graphql`
    query($slug: String!) {
        site {
            siteMetadata {
                courseId
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            htmlAst
        }
    }
`